//-----Dan Barry 2022----------
//This code adds Cloudflare Analytics (cookieless), Google Analytics (with consent) and cookie consent banner code provided by https://www.FreePrivacyPolicy.com

//----- Usage ---------------------------------
//Place the followng two script tags in the <head> of any page to be tracked
//      <script async src="https://www.googletagmanager.com/gtag/js?id=G-59PSHZ59L1"></script>
//      <script src="visits.js"></script>


  window.aichatbotApiKey = "d266bfc3-1a0b-418b-a4ec-0883e3c6e442";
  window.aichatbotProviderId = "f9e9c5e4-6d1a-4b8c-8d3f-3f9e9c5e46d1";
  
  chatscript = document.createElement('script');
  chatscript.src = "https://script.chatlab.com/aichatbot.js";
  chatscript.id = "d266bfc3-1a0b-418b-a4ec-0883e3c6e442";
  chatscript.defer = true;
  
  


// Use cookieless Cloudflare visit counting
script = document.createElement('script');
// script.defer = true; 
script.src = 'https://static.cloudflareinsights.com/beacon.min.js';
script.dataset.cfBeacon = '{"token": "6a50449332d942a49d8a7b1015096ea5"}';

window.onload = function() {

  // consentCheck();
  window.document.head.appendChild(chatscript);

  window.document.body.appendChild(script);
  window.document.body.appendChild(scriptCookie);
  window.document.head.appendChild(ga_script);
  
  
}

// Use Google Analytics if consent is given under tracking category

ga_script = window.document.createElement('script');
ga_script.type = "text/plain";
ga_script.defer = true;
// ga_script.src = 'https://www.googletagmanager.com/gtag/js?id=G-59PSHZ59L1';
ga_script.setAttribute("cookie-consent", "tracking");
ga_script.innerHTML = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('consent', 'update', {ad_storage: 'denied',analytics_storage: 'granted',functionality_storage: 'granted',personalization_storage: 'denied',security_storage: 'denied'});gtag('config', 'G-59PSHZ59L1', {'anonymize_ip': true});";
//window.document.head.appendChild(ga_script);





//  Cookie Consent by FreePrivacyPolicy.com https://www.FreePrivacyPolicy.com


var path = window.location.pathname
// console.log(path);

if (!botCheck()){

  if (path!= "/tuner.html" & path!= "/metronome.html" & path!= "/metronome-embed.html" & path!= "/tuner-embed.html" & path!= "/chords" & path!= "/chords/embedtool"){

  scriptCookie = window.document.createElement('script');
  scriptCookie.defer = true;
  scriptCookie.type = 'text/javascript';
  scriptCookie.src = 'https://www.freeprivacypolicy.com/public/cookie-consent/4.0.0/cookie-consent.js';
  // window.document.body.appendChild(scriptCookie);

      window.scriptCookie.onload = function () {

            window.cookieconsent.run({"notice_banner_type":"headline","consent_type":"express","palette":"dark","language":"en","page_load_consent_levels":["strictly-necessary"],"notice_banner_reject_button_hide":false,"preferences_center_close_button_hide":false,"page_refresh_confirmation_buttons":false,"website_name":"https://guitarapp.com","website_privacy_policy_url":"https://guitarapp.com/privacy.html"});

            window.document.getElementById("cc-nb-text").innerHTML = "We use cookies to deliver this service to you. Learn more about the <a href='https://guitarapp.com/cookies.html'>cookies we use</a> here. ";

            window.document.querySelectorAll('.cc-nb-okagree').forEach(e => e.style.backgroundColor = '#4288e2');
            window.document.querySelectorAll('.cc-nb-okagree').forEach(e => e.style.color = '#e9e9e9');

            window.document.querySelectorAll('.cc-nb-reject').forEach(e => e.style.backgroundColor = '#4288e2');
            window.document.querySelectorAll('.cc-nb-reject').forEach(e => e.style.color = '#e9e9e9');

            // window.document.getElementById(id="freeprivacypolicy-com---nb").style.marginBottom = "-233px";
            window.document.getElementById(id="freeprivacypolicy-com---nb").style.bottom = "0%";
            window.document.getElementById(id="freeprivacypolicy-com---nb").style.position = "fixed";


            window.document.addEventListener("click", function () {
                // to style the preference centre

                window.document.querySelectorAll('.cc-cp-foot-save').forEach(e => e.style.backgroundColor = '#4288e2');
                window.document.querySelectorAll('.cc-cp-foot-save').forEach(e => e.style.color = '#e9e9e9');

                window.document.querySelectorAll('.cc-pc-head-lang-select').forEach(e => e.style.display= 'none');

            });

      };

  }
}




function botCheck(){
  var botPattern = "(googlebot\/|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
            var re = new RegExp(botPattern, 'i');
            var userAgent = navigator.userAgent;
            if (re.test(userAgent)) {
                return true;
            }else{
              return false;
            }
  }

 